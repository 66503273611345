import React from "react";
import styled from "styled-components";

type Props = {
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  style?: React.CSSProperties;
  className?: string;
  id?: string;
  disabled?: boolean;
  ref?: React.Ref<HTMLButtonElement>;
};

export const StyledButton = ({
  children,
  onClick,
  style,
  className,
  disabled,
  ref,
  id,
}: Props) => {
  const classNames = className ? `StyledButton ${className}` : "StyledButton";
  return (
    <Button
      ref={ref}
      className={classNames}
      onClick={onClick}
      style={style}
      disabled={disabled}
      id={id}
    >
      <ButtonContent>{children}</ButtonContent>
    </Button>
  );
};

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  box-sizing: border-box;
  color: #ffffff;
  font-size: ${({ theme }) => theme.fontSizeS};
  font-weight: ${({ theme }) => theme.fontBold};
  line-height: 120%;
  text-align: center;
  position: relative;
  cursor: pointer;
  outline: none;
  border-radius: 8px;
  z-index: 1;
  border: none;
  padding: 1px;

  &:disabled > span {
    background: #cacaca;
  }
  &:active > span {
    background: linear-gradient(90deg, #c150e9 46%, #5dacdc 100%);
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    border-radius: inherit;
    width: 100%;
    height: 100%;
    background: linear-gradient(94.58deg, #55b3db 14.96%, #c150e9 67.02%);
  }
`;

const ButtonContent = styled.span`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  z-index: 1;
  position: relative;
  padding: 0px 16px;
  background-color: #333;
  transition: all 0.2s ease-in-out;
`;
