import { IGame } from "../types/game";

const API_URL = `${
  process.env.REACT_APP_API_GAME_SERVICE_URL ||
  "https://gameservice.develop.erth.xyz"
}/api/games`;

export const getGames = async () => {
  try {
    const response = await fetch(API_URL);
    if (!response.ok) {
      console.error("Network response was not ok");
      return undefined;
    }
    const data = await response.json();
    return data as IGame[];
  } catch (error) {
    console.error("There was a problem fetching the games:", error);
  }
};
