import styled from "styled-components";
import { flexCenter, regularFont } from "../../styles/theme";

const secondaryContainerRadius = "8px";

export const Block = styled.div<{ loggedIn?: boolean }>`
  ${flexCenter};
  flex-direction: column;

  grid-row: 1 / span 2;
  grid-column: 1 / span 2;
  justify-content: space-between;
  border-radius: 12px;
  padding: 16px 0;
  background: #082c3c;
  border: 1px solid #0e4c68;
  border-radius: 24px;

  .text {
    ${regularFont};
    color: #e7e7e7 !important;
    text-align: center;
    padding: 0 8px;
    line-height: 130% !important;
    font-weight: 500;
    max-width: 90%;
  }

  .title {
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    text-align: center;
  }
  .username {
    ${regularFont};
    font-weight: ${({ theme }) => theme.fontBold};
    padding: 8px 12px;
    border-radius: ${secondaryContainerRadius};
    background-color: ${({ theme }) => theme.homepageProgressBarSecondary};
  }

  .customBtn {
    width: 168px;
    border-radius: 24px;
    border: 1px solid #0ea5e9;
    background: #0284c7;
    & > span,
    &:before {
      background: transparent;
    }
  }

  @media only screen and (max-width: 1919px) {
    row-gap: ${({ loggedIn }) => (loggedIn ? "10px" : "30px")};
    .text {
      ${regularFont};
      font-weight: 500;
    }
  }
  @media only screen and (max-width: 1259px) {
    .text {
      ${regularFont};
      font-weight: 500;
    }
  }
`;

export const ButtonFlexContainer = styled.div<{ column?: boolean }>`
  ${flexCenter};
  flex-direction: ${({ column }) => (column ? "column" : "row")};
  gap: 11px;
  margin-top: auto;
  .flexItem {
    width: 134px;
    height: 40px;
  }
  @media only screen and (max-width: 1919px) {
    .flexItem {
      width: 115px;
    }
  }
  @media only screen and (max-width: 1419px) {
    .flexItem {
      width: 100px;
    }
  }
  @media only screen and (max-width: 1159px) {
    .flexItem {
      width: 85px;
    }
  }
`;

export const PlaycoinValue = styled.div`
  ${regularFont};
  font-weight: ${({ theme }) => theme.fontMedium};
  ${flexCenter};
  justify-content: space-between;
  padding: 9px 16px;
  border-radius: ${secondaryContainerRadius};
  background-color: ${({ theme }) => theme.homepageBoxBgSecondary};
  .playcoin {
    width: 22px;
    height: 22px;
  }
`;

export const UserImage = styled.div<{ image: string }>`
  width: 133px;
  height: 133px;
  background: transparent;
  cursor: pointer;
  ${({ image }) =>
    image &&
    `
  background-image: url(${image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  `}

  @media only screen and (max-width: 1919px) {
    width: 90px;
    height: 90px;
  }
`;
