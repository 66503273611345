import styled from "styled-components";

export const Wrapper = styled.div`
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.homepageMainBg};
  width: 100vw;
  min-height: 100svh;
  overflow: hidden auto;
  color: #fff;
`;

export const GridWrapper = styled.div`
  max-width: 1840px;
  margin: 0 auto;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 155px);
  grid-auto-rows: 155px;
  justify-content: center;
  align-content: center;
  row-gap: 30px;
  column-gap: 35px;

  @media only screen and (max-width: 1919px) {
    grid-template-columns: repeat(10, 120px);
    grid-auto-rows: 120px;
    row-gap: 25px;
    column-gap: 20px;
  }

  @media only screen and (max-width: 1419px) {
    grid-template-columns: repeat(10, 110px);
    grid-auto-rows: 110px;
  }

  @media only screen and (max-width: 1364px) {
    row-gap: 20px;
    column-gap: 15px;
  }

  @media only screen and (max-width: 1259px) {
    grid-template-columns: repeat(10, 100px);
    grid-auto-rows: 100px;
  }

  @media only screen and (max-width: 1159px) {
    grid-template-columns: repeat(10, 90px);
    grid-auto-rows: 90px;
    column-gap: 10px;
  }
`;

export const GridBlock = styled.div<{
  image: string;
  $gridRowStart: number;
  $gridRowEnd: number;
  $gridColumnStart: number;
  $gridColumnEnd: number;
}>`
  grid-row-start: ${({ $gridRowStart }) => $gridRowStart};
  grid-row-end: ${({ $gridRowEnd }) => $gridRowEnd};
  grid-column-start: ${({ $gridColumnStart }) => $gridColumnStart};
  grid-column-end: ${({ $gridColumnEnd }) => $gridColumnEnd};
  background-color: ${({ theme }) => theme.homepageBoxBgPrimary};
  border-radius: 12px;
  cursor: pointer;
  ${({ image }) =>
    image &&
    `
  background-image: url(${image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
`}
`;
