import React from "react";
import styled, { keyframes } from "styled-components";
import logoMochi from "../../assets/logo-mochi.svg";
import { flexCenter } from "../../styles/theme";
import { useNavigate } from "react-router-dom";
import { StyledButton } from "../../common/StyledButton";

type Props = {
  withoutLogin?: boolean;
};
export const Header = ({ withoutLogin }: Props) => {
  const navigate = useNavigate();
  return (
    <StyledHeader>
      <LogoContainer>
        <img
          src={logoMochi}
          onClick={() => navigate("/")}
          alt="mochi"
          className="logo-mochi"
          width={96}
        />
      </LogoContainer>
      {!withoutLogin && (
        <ButtonsWrapper>
          <StyledButton
            className="header-button"
            onClick={() => navigate("/comingsoon")}
            style={{ background: "#0C4A6E" }}
          >
            Login
          </StyledButton>
          <StyledButton
            className="header-button"
            onClick={() => navigate("/comingsoon")}
            style={{ background: "#0284C7", border: "1px solid #0EA5E9" }}
          >
            Sign Up
          </StyledButton>
        </ButtonsWrapper>
      )}
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  max-width: 1840px;
  margin: 0 0 29px;
  background-color: transparent;
  width: 100%;
  max-width: 1868px;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #082c3c;
  border-bottom: 1px solid #0e4c68;
  padding: 0 16px;
  .logo-erth {
    width: 85px;
    height: 25px;
  }

  .logo-mochi {
    cursor: pointer;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  .header-button {
    width: 88px;
    height: 32px;
    border-radius: 24px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    &:before {
      display: none;
    }
    > span {
      background: transparent;
    }
  }
`;

const LogoContainer = styled.div`
  ${flexCenter};
  column-gap: 16px;
  height: 100%;
  .divider {
    width: 1px;
    height: 100%;
    background-color: #fff;
  }
`;
