import { css } from "styled-components";

export const theme = {
  homepageMainBg: "#020e13",
  homepageBoxBgPrimary: "#082c3c",
  homepageBoxBgSecondary: "#041e29",
  homepageBoxBgTertiary: "#041e29",
  homepageButtonPrimary: `linear-gradient(
    90deg,
    #1192ce 0%,
    #086997 100%
  )`,
  homepageUserProfileDetailsColor: "#000",
  homepageUserProfileItemBoxColor: "#083447",
  homepageUserProfileQuestBg: "#9195f61a",
  homepageColorCompleted: "#8be8e5",
  homepageProgressBar: "#e28080",
  homepageProgressBarSecondary: "#6f61c0",
  homepageScrollbarBg: "#000",
  homepageScrollbarColor: "#1192CE",
  homepageFontFamilySecondary: "Staatliches",
  fontMainSelectedColor: "#fff",
  fontUnselectedColor: "#b4b0b0",
  fontUnselectedTabColor: "#5c5c5c",

  fontBold: 700,
  fontMedium: 500,
  fontRegular: 400,

  fontSizeXl: "24px",
  fontSizeL: "20px",
  fontSizeM: "18px",
  fontSizeS: "16px",
  fontSizeXs: "14px",
};

export const flexCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const regularFont = css`
  font-size: ${theme.fontSizeS};
  font-weight: ${theme.fontRegular};
  line-height: 120%;
`;
