export const truncateName = (name: string, limit: number) => {
  if (name.length > limit) {
    return name.slice(0, limit) + "...";
  }
  return name;
};

export const formatNumber = (n: number, maxWithoutK?: number) => {
  if (maxWithoutK && n > maxWithoutK) {
    return Math.round(n / 1000).toLocaleString() + "k";
  } else if (n > 99999) {
    return Math.round(n / 1000).toLocaleString() + "k";
  } else {
    return n.toLocaleString();
  }
};

export const getProgressPercentage = (args: {
  completed: number;
  total: number;
}) => {
  const { total, completed } = args;
  if (total <= 0) {
    return console.error("Total number of tasks must be greater than zero.");
  }
  if (completed < 0) {
    return console.error("Number of completed tasks cannot be negative.");
  }
  if (completed > total) {
    return console.error(
      "Number of completed tasks cannot exceed total number of tasks."
    );
  }

  const progress = (completed / total) * 100;
  return `${progress}%`;
};
