import React, { useEffect, useState } from "react";
import { Grid, GridWrapper, Wrapper } from "./Home.style";
import { IGame } from "../../types/game";
import { SingleGame } from "../../components/SingleGame/SingleGame";
import { getGames } from "../../api/game";
import { Header } from "../../components/Header/Header";
import { LoginBlock } from "../../components/LoginBlock/LoginBlock";

export const Home = ({ playPage }: { playPage?: boolean }) => {
  const [games, setGames] = useState<IGame[]>([]);

  useEffect(() => {
    const container = document.getElementById("container");
    if (container) {
      container.style.overflow = "hidden auto";
    }
    const fetchGames = async () => {
      const response = await getGames();
      response && setGames(response);
    };
    fetchGames();
  }, []);

  return (
    <Wrapper>
      <Header />
      <GridWrapper>
        <Grid>
          <LoginBlock />

          {games.map((game, index) => {
            return <SingleGame key={game.name + index} game={game} />;
          })}
        </Grid>
      </GridWrapper>
    </Wrapper>
  );
};
