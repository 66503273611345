import React, { useEffect, useState } from "react";
import playcoinImg from "../../assets/playcoin.png";
import erthling from "../../assets/erthling.png";
import {
  Block,
  ButtonFlexContainer,
  PlaycoinValue,
  UserImage,
} from "./LoginBlock.style";
import { getBalance } from "../../api/currency";
import { StyledButton } from "../../common/StyledButton";
import { useAuthorizationContext } from "../../context";
import { useNavigate } from "react-router-dom";

export const LoginBlock = () => {
  const { isAuthorized, googleUser, dbUser } = useAuthorizationContext();
  const [playcoinValue, setPlaycoinValue] = useState<number>();
  const navigate = useNavigate();

  useEffect(() => {
    const getPlayCoinValue = async () => {
      const res = await getBalance();
      if (!res) return;
      setPlaycoinValue(res.grantedCoins);
    };
    getPlayCoinValue();
  }, []);

  return isAuthorized && googleUser && dbUser ? (
    <Block loggedIn={true}>
      <ButtonFlexContainer column={true}>
        <UserImage image={googleUser.photoURL || erthling} />
        <span className="username">{googleUser.displayName}</span>
      </ButtonFlexContainer>
      <ButtonFlexContainer>
        <PlaycoinValue className="flexItem">
          <img src={playcoinImg} alt="PlayCoin" />
          {playcoinValue ? playcoinValue.toLocaleString() : "loading..."}
        </PlaycoinValue>
        <StyledButton
          id="redeemBtn"
          onClick={() => navigate("/comingsoon")}
          className="customBtn flexItem redeemBtn"
        >
          Redeem
        </StyledButton>
      </ButtonFlexContainer>
    </Block>
  ) : (
    <Block>
      <span className="title">Welcome to Mochi!</span>
      <span className="text">Play free games and earn PlayCoin!</span>
      <StyledButton
        id="signUpBtn"
        className="customBtn sign-up"
        onClick={() => navigate("/comingsoon")}
      >
        Sign Up
      </StyledButton>
    </Block>
  );
};
