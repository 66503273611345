import { styled } from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100svh;
  display: flex;
  flex-direction: column;
  background: #031017;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.homepageBoxBgPrimary};
  border-bottom: 1px solid #0e4c68;
  height: 56px;
  padding: 0 16px;
  cursor: pointer;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100svh - 56px - 56px);
  position: relative;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid #082c3c;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  color: #ffffff99;
  height: 56px;
`;

export const Modal = styled.div`
  background: ${({ theme }) => theme.homepageBoxBgPrimary};
  width: 480px;
  height: 232px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #0e4c68;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  > img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const ModalTitle = styled.div`
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
  color: white;
`;

export const ModalDesc = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: white;
`;

export const ModalButton = styled.div`
  width: 349px;
  background: linear-gradient(90.84deg, #1192ce 50.04%, #086997 99.28%);
  height: 48px;
  border-radius: 24px;
  cursor: pointer;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: white;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  display: flex;
  align-items: center;
  justify-content: center;
`;
