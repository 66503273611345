import { UserInfo } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import { IUser } from "../types/user";

export const getLikedGames = async (googleUser: UserInfo) => {
  try {
    const id = googleUser.uid;
    const docRef = doc(db, "users", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const user = docSnap.data() as IUser;
      return user.likedGames || [];
    } else {
      console.log("No such user in db! ");
    }
  } catch (e) {
    console.error("Error from fetching document: ", e);
  }
};
