import React, { useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import likeIcon from "../../assets/like.svg";
import playIcon from "../../assets/play.svg";
import { IGame } from "../../types/game";
import { formatNumber } from "../../services/formating";
import { flexCenter } from "../../styles/theme";

const slide = (x: number) => keyframes`
  0% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(-${x + 10}px);
  }
`;

interface Props {
  game: IGame;
}

export const GameOverlay = ({ game }: Props) => {
  const { name, layout, likes, playCount } = game;
  const { size } = layout;
  const nameContainerRef = React.useRef<HTMLSpanElement>(null);
  const nameRef = React.useRef<HTMLSpanElement>(null);
  const [lengthDiffrence, setLengthDiffrence] = useState(0);
  const [animationSpeed, setAnimationSpeed] = useState(0);
  //{size === 1 ? truncateName(name, 13) : truncateName(name, 17)}

  useEffect(() => {
    if (nameContainerRef.current && nameRef.current) {
      setLengthDiffrence(
        nameRef.current.clientWidth - nameContainerRef.current.clientWidth
      );
      setAnimationSpeed(
        nameRef.current.clientWidth / nameContainerRef.current.clientWidth
      );
    }
  }, [nameContainerRef, nameRef]);

  return (
    <Overlay $isSmallTile={size === 1} className="game-overlay">
      <div className="top">
        <span className="name" ref={nameContainerRef}>
          <GameName
            $isAnimated={lengthDiffrence > 0}
            $diff={lengthDiffrence}
            $speed={animationSpeed}
            ref={nameRef}
          >
            {name}
          </GameName>
        </span>
        <span className="producedBy">.GG</span>
      </div>
      <BottomSection $isSmallTile={size === 1}>
        <div className="data likes" data-id={game.id}>
          <img src={likeIcon} alt="number of likes" className="icon" />
          <span>{likes}</span>
        </div>
        <div className="data playCount" data-id={game.id}>
          <img src={playIcon} alt="number of players" className="icon" />
          <span>{formatNumber(playCount)}</span>
        </div>
      </BottomSection>
    </Overlay>
  );
};

export const Overlay = styled.div<{ $isSmallTile: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000000b2;
  ${flexCenter};
  flex-direction: column;
  row-gap: 10%;

  .name {
    font-size: ${({ theme }) => theme.fontSizeL};
    font-weight: ${({ theme }) => theme.fontBold};
    white-space: nowrap;
    position: relative;
    height: 24px;
    width: 90%;
    overflow: hidden;
    text-align: center;
  }

  .producedBy {
    font-size: ${({ theme }) => theme.fontSizeS};
    font-weight: ${({ theme }) => theme.fontMedium};
    color: ${({ theme }) => theme.fontUnselectedColor};
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    row-gap: 4px;
  }

  ${({ $isSmallTile }) =>
    $isSmallTile &&
    `
@media only screen and (max-width: 1919px) {
  .name {
    font-size: 16px;
    height: 16px;
  }

  .producedBy {
    font-size: 14px;;
  }

  @media only screen and (max-width: 1159px) {
    .name {
      font-size: 14px;
      height: 14px;
    }
  }
`}
`;

export const GameName = styled.span<{
  $isAnimated: boolean;
  $diff: number;
  $speed: number;
}>`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  ${({ $isAnimated, $diff, $speed }) =>
    $isAnimated &&
    css`
      left: 0;
      transform: translateX(10px);
      animation: ${slide($diff)} ${$speed}s linear 0.2s infinite alternate;
    `}
`;

const BottomSection = styled.div<{ $isSmallTile: boolean }>`
  ${flexCenter};
  width: 100%;
  column-gap: 15%;
  font-size: ${({ theme }) => theme.fontSizeS};
  font-weight: ${({ theme }) => theme.fontMedium};

  .data {
    ${flexCenter};
    flex-direction: column;
    row-gap: 12px;
    .icon {
      width: 23px;
    }
  }

  ${({ $isSmallTile }) =>
    $isSmallTile &&
    `
  @media only screen and (max-width: 1919px) {
  .data {
    row-gap: 8px;
    .icon {
      width: 19px;
    }
  }}
  @media only screen and (max-width: 1439px) {
    font-size: 14px;
  .data {
    .icon {
      width: 17px;
    }
  }}
`}
`;
