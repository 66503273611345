const BASE_URL =
  process.env.REACT_APP_API_GAME_CURRENCY_URL ||
  "https://gamecurrency.develop.erth.xyz";

const apiKey = "apiKey1";
const serverId = "server1";
const userId = "nitin";

export const getBalance = async () => {
  try {
    const response = await fetch(`${BASE_URL}/balance`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": apiKey,
        "x-server-id": serverId,
        "x-user-id": userId,
      },
    });
    if (!response.ok) {
      console.error("Network response was not ok");
      return undefined;
    }
    const data = await response.json();
    return data as { grantedCoins: number };
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
  }
};
