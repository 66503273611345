// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: "Roboto";
  letter-spacing: -0.5px;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

:focus {
  outline: none;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar:horizontal {
  height: 0.5em;
}

::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 0;
  background-color: #000;
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #1192ce;
  box-shadow: none;
}

::-webkit-scrollbar-thumb:window-inactive {
  background-color: #000;
}

@supports (-moz-appearance: none) {
  html {
    scrollbar-width: thin;
    scrollbar-color: #1192ce #000;
  }
}

button:disabled {
  cursor: default !important;
}

button.reset-css {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  border: none;
  background: none;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,sBAAsB;EACtB,sBAAsB;AACxB;;AAEA;EACE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE;IACE,qBAAqB;IACrB,6BAA6B;EAC/B;AACF;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,UAAU;EACV,SAAS;EACT,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":["* {\n  font-family: \"Roboto\";\n  letter-spacing: -0.5px;\n  box-sizing: border-box;\n}\n\nbody {\n  margin: 0;\n  padding: 0;\n}\n\n:focus {\n  outline: none;\n}\n\n::-webkit-scrollbar {\n  width: 5px;\n}\n\n::-webkit-scrollbar:horizontal {\n  height: 0.5em;\n}\n\n::-webkit-scrollbar-track {\n  box-shadow: none;\n  border-radius: 0;\n  background-color: #000;\n}\n\n::-webkit-scrollbar-thumb {\n  border-radius: 0;\n  background: #1192ce;\n  box-shadow: none;\n}\n\n::-webkit-scrollbar-thumb:window-inactive {\n  background-color: #000;\n}\n\n@supports (-moz-appearance: none) {\n  html {\n    scrollbar-width: thin;\n    scrollbar-color: #1192ce #000;\n  }\n}\n\nbutton:disabled {\n  cursor: default !important;\n}\n\nbutton.reset-css {\n  padding: 0;\n  margin: 0;\n  box-sizing: border-box;\n  border: none;\n  background: none;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
