import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { UserInfo } from "firebase/auth";
import { setDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { IUser } from "../types/user";
import { auth, db } from "../firebase";
import { getLikedGames } from "../api/user";

interface AuthorizationContextValue {
  isAuthorized: boolean;
  setIsAuthorized: React.Dispatch<React.SetStateAction<boolean>>;
  onLogOut: () => void;
  googleUser: UserInfo | null;
  authToken: string | null;
  initLoading: boolean;
  dbUser: IUser | null;
  setDbUser: React.Dispatch<React.SetStateAction<IUser | null>>;
  likedGames: number[];
  setLikedGames: React.Dispatch<React.SetStateAction<number[]>>;
  handleGetLikedGames: () => Promise<void>;
}

export const AuthorizationContext =
  React.createContext<AuthorizationContextValue>(null!);

export interface AuthorizationContextProviderProps {
  children: React.ReactNode;
}

const AuthorizationContextProvider: React.FC<
  AuthorizationContextProviderProps
> = ({ children }) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [googleUser, setGoogleUser] = useState<UserInfo | null>(null);
  const [authToken, setAuthToken] = useState<string | null>(null);
  const [initLoading, setInitloading] = useState(true);
  const [dbUser, setDbUser] = useState<IUser | null>(null);
  const [likedGames, setLikedGames] = useState<number[]>([]);

  const onLogOut = () => {
    setIsAuthorized(false);
    setGoogleUser(null);
    setDbUser(null);
  };

  useEffect(() => {
    auth.onAuthStateChanged(async function (user) {
      if (user) {
        const token = await user.getIdToken(true);
        setGoogleUser(user.providerData[0]);
        setAuthToken(token);
        setIsAuthorized(true);
        setInitloading(false);
      } else {
        setGoogleUser(null);
        setAuthToken(null);
        setInitloading(false);
      }
    });
  }, []);

  const createNewUser = useCallback(async () => {
    try {
      if (!googleUser) {
        console.log("Cannot create user, google login data missing");
        return;
      }

      let user: IUser;

      user = {
        id: googleUser.uid,
        name: googleUser.displayName || "",
        email: googleUser.email || "",
        avatar: googleUser.photoURL || "",
        memberSince: moment().unix(),
      };

      const id = googleUser.uid;
      await setDoc(doc(db, "users", id), user);

      const docRef = doc(db, "users", id);
      const docSnap = await getDoc(docRef);
      setDbUser(docSnap.data() as IUser);
    } catch (e) {
      console.error("Error from adding document: ", e);
    }
  }, [googleUser]);

  const getUser = useCallback(
    async (userId?: string) => {
      try {
        if (!userId && !googleUser) {
          console.log("Cannot create user, google login data missing");
          return;
        }

        const id = userId || googleUser!.uid;

        const docRef = doc(db, "users", id);
        const docSnap = await getDoc(docRef);

        if (userId) {
          return docSnap.data() as IUser;
        }

        if (docSnap.exists()) {
          const user = docSnap.data() as IUser;
          if (!user.memberSince) {
            await updateDoc(docRef, {
              memberSince: moment().unix(),
            });
          }
          setDbUser(user);
        } else {
          console.log("No such user in db! Adding new one");
          createNewUser();
        }
      } catch (e) {
        console.error("Error from fetching document: ", e);
      }
    },
    [createNewUser, googleUser]
  );

  useEffect(() => {
    googleUser && getUser();
  }, [getUser, googleUser]);

  const handleGetLikedGames = async () => {
    if (!googleUser) return;
    const res = await getLikedGames(googleUser);
    res && setLikedGames(res);
  };

  useEffect(() => {
    googleUser && handleGetLikedGames();
  }, [googleUser]);

  return (
    <AuthorizationContext.Provider
      value={{
        isAuthorized,
        setIsAuthorized,
        onLogOut,
        googleUser,
        authToken,
        initLoading,
        dbUser,
        setDbUser,
        likedGames,
        setLikedGames,
        handleGetLikedGames,
      }}
    >
      {children}
    </AuthorizationContext.Provider>
  );
};

export default AuthorizationContextProvider;
