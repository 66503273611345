import React, { useState } from "react";
import { IGame } from "../../types/game";
import { IMAGE_URL } from "../../constants";
import { GridBlock } from "../../pages/Home/Home.style";
import { GameOverlay } from "../GameOverlay/GameOverlay";
import { useNavigate } from "react-router-dom";

interface Props {
  game: IGame;
  recommendedRowStart?: number;
  recommendedRowEnd?: number;
  recommendedColumnStart?: number;
  recommendedColumnEnd?: number;
}

export const SingleGame = ({
  game,
  recommendedRowStart,
  recommendedRowEnd,
  recommendedColumnStart,
  recommendedColumnEnd,
}: Props) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const { name, thumbnail, layout, id } = game;
  const { col, row, size } = layout;
  const span = Math.sqrt(size);

  return (
    <GridBlock
      className="game-tile"
      data-id={id}
      data-name={name}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      image={`${IMAGE_URL}/${thumbnail}`}
      $gridRowStart={recommendedRowStart || row}
      $gridRowEnd={recommendedRowEnd || row + span}
      $gridColumnStart={recommendedColumnStart || col}
      $gridColumnEnd={recommendedColumnEnd || col + span}
      onClick={() => navigate("/comingsoon")}
    >
      {isHovered && <GameOverlay game={game} />}
    </GridBlock>
  );
};
