import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "styled-components";
import { theme } from "./styles/theme";
import { Home } from "./pages/Home/Home";
import "./index.css";
import AuthorizationContextProvider from "./context/AuthorizationContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ComingSoon } from "./pages/ComingSoon/ComingSoon";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AuthorizationContextProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/comingsoon" element={<ComingSoon />} />
          </Routes>
        </BrowserRouter>
      </AuthorizationContextProvider>
    </ThemeProvider>
  </React.StrictMode>
);
