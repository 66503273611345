import {
  Container,
  Content,
  Footer,
  Modal,
  ModalButton,
  ModalDesc,
  ModalTitle,
} from "./ComingSoon.style";
import logoMochi from "../../assets/logo-mochi.svg";
import { useNavigate } from "react-router-dom";
import { Header } from "../../components/Header/Header";

export const ComingSoon = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Header withoutLogin={true} />
      <Content>
        <Modal>
          <img src={logoMochi} alt="mochi" className="logo-mochi" width={240} />
          <ModalTitle>Coming soon!</ModalTitle>
          <ModalDesc>We’re working on bringing you the best games!</ModalDesc>
          <ModalButton onClick={() => navigate("/")}>Back to Home</ModalButton>
        </Modal>
      </Content>
      <Footer>
        <span>Privacy Policy</span>&nbsp;•&nbsp;<span>Terms of Service</span>
      </Footer>
    </Container>
  );
};
